<div class="zoom-out">
	<input
		type="file"
		#resumeInput
		id="profile-pic"
		accept="image/x-png,image/gif,image/jpeg"
		(change)="addFileDetails()"
		style="position: absolute; visibility: hidden"
	/>

	<div class="container-fluid mt-3" style="height: 95vh">
		<div class="row" style="height: 95vh">
			<div class="col-sm-12 text-right">
				<div class="row mb-3">
					<div class="text-left player-name">
						<span
							>{{ playerName }} - {{ sourceteam }} -
							{{ position }}</span
						>
					</div>

					<div class="col-sm-2 text-right upper-bar-text">
						Min Matches
						<input
							class="form-control min-field"
							[(ngModel)]="minMatches"
							(change)="onMinMatchesChange()"
							min="1"
							step="1"
							inputmode="numeric"
							(input)="onInput($event)"
						/>
					</div>

					<div class="col-sm-2 text-right upper-bar-text">
						Player Detailed
						<mat-slide-toggle></mat-slide-toggle>
					</div>
				</div>

				<div class="custom-hr-container">
					<img
						src="assets/runner.png"
						class="hr-image"
						alt="Overlapping Image"
					/>
					<hr class="custom-hr" />
				</div>
			</div>

			<div class="col-sm-8">
				<div
					class="col-sm-12"
					id="chart"
					style="
						position: relative;
						z-index: 2;
						width: 100%;
						height: 40vh;
						border: 1px solid black;
					"
				></div>

				<div class="table-heading col-sm-12">
					<span>2025 - Top 10 (min {{ minMatches }} matches)</span>
				</div>

				<div
					class="table-wrapper report-component col-sm-12"
					*ngIf="topPlayersData.length"
				>
					<table>
						<thead>
							<tr>
								<th>Rank</th>
								<th>Player</th>
								<th>Team</th>
								<th>Position</th>
								<th class="equal-width-column">MAT</th>
								<th class="equal-width-column">MINS</th>
								<th class="equal-width-column">CVG</th>
								<th class="equal-width-column">CVM</th>
								<th class="equal-width-column">CPC %</th>
								<th class="equal-width-column">NEGS</th>
								<th class="equal-width-column">WIN %</th>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="let player of topPlayersData"
								[ngClass]="{
									highlight: player.player === playerName
								}"
							>
								<td>{{ player.rank }}</td>
								<td>{{ player.player | capitalizeWords }}</td>
								<td>{{ player.team }}</td>
								<td>{{ player.position }}</td>
								<td class="equal-width-column">
									{{ player.mat }}
								</td>
								<td class="equal-width-column">
									{{ player.mins }}
								</td>
								<td class="equal-width-column">
									<div class="progress">
										<div
											class="progress-bar-cvg"
											role="progressbar"
											[style.width]="
												getPercentageWidth(
													player.cvg,
													maxCvg
												)
											"
											[attr.aria-valuenow]="player.cvg"
											aria-valuemin="0"
											aria-valuemax="100"
										></div>
									</div>
									<span class="td-value">{{
										player.cvg | number : "1.0-0"
									}}</span>
								</td>
								<td class="equal-width-column">
									<div class="progress">
										<div
											class="progress-bar-cvm"
											role="progressbar"
											[style.width]="
												getPercentageWidth(
													player.cvm,
													maxCvm
												)
											"
											[attr.aria-valuenow]="player.cvm"
											aria-valuemin="0"
											aria-valuemax="100"
										></div>
									</div>
									<span class="td-value">{{
										player.cvm
									}}</span>
								</td>
								<td class="equal-width-column">
									<div class="progress">
										<div
											class="progress-bar-creativity"
											role="progressbar"
											[style.width]="
												getPercentageWidth(
													player.creativity,
													maxCreativity
												)
											"
											[attr.aria-valuenow]="
												player.creativity
											"
											aria-valuemin="0"
											aria-valuemax="100"
										></div>
									</div>
									<span class="td-value"
										>{{
											player.cpc | number : "1.0-0"
										}}%</span
									>
								</td>
								<td class="equal-width-column">
									<div class="progress">
										<div
											class="progress-bar-negs2"
											role="progressbar"
											[style.width]="
												getPercentageWidth(
													player.absNegs,
													maxAbsNegs
												)
											"
											[attr.aria-valuenow]="player.negs"
											aria-valuemin="0"
											aria-valuemax="100"
										></div>
									</div>
									<span class="td-value">{{
										player.negs | number : "1.0-0"
									}}</span>
								</td>
								<td class="equal-width-column">
									<div class="progress">
										<div
											class="progress-bar-negs"
											role="progressbar"
											[style.width]="
												getPercentageWidth(
													player.winPercentage,
													100
												)
											"
											[attr.aria-valuenow]="
												player.winPercentage
											"
											aria-valuemin="0"
											aria-valuemax="100"
										></div>
									</div>
									<span class="td-value"
										>{{
											player.winPercentage
												| number : "1.0-0"
										}}%</span
									>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="col-sm-4 additional-content">
				<div class="additional-content-inner">
					<div class="row mb-3">
						<div class="col-sm-6 text-left chart-heading">
							<span>Key Performance Indicators</span>
						</div>
					</div>

					<div class="custom-hr-container-table">
						<img
							src="assets/performance.png"
							class="hr-image1"
							alt=""
						/>
						<hr class="custom-hr" />
					</div>

					<div *ngIf="averageData2023 && averageData2024">
						<!-- Upper Table -->
						<table class="average-table">
							<thead>
								<tr>
									<th></th>
									<th>Current</th>
									<th>2024</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>CVG</td>
									<td>
										{{
											averageData2024.avgCvg
												| number : "1.0-0"
										}}
									</td>
									<td>
										{{
											averageData2023.avgCvg
												| number : "1.0-0"
										}}
									</td>
								</tr>
								<tr>
									<td>CVM</td>
									<td>
										{{
											averageData2024.avgCvm
												| number : "1.1-1"
										}}
									</td>
									<td>
										{{
											averageData2023.avgCvm
												| number : "1.1-1"
										}}
									</td>
								</tr>
								<tr>
									<td>CPC %</td>
									<td>
										{{
											averageData2024.avgCpc
												| number : "1.0-0"
										}}
										%
									</td>
									<td>
										{{
											averageData2023.avgCpc
												| number : "1.0-0"
										}}
										%
									</td>
								</tr>
								<tr>
									<td>NEGS</td>
									<td>{{ averageData2024.avgNegs }}</td>
									<td>{{ averageData2023.avgNegs }}</td>
								</tr>
								<tr>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>

						<!-- Lower Table -->
						<table class="average-table">
							<thead>
								<tr>
									<th></th>
									<th>Current</th>
									<th>2024</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Matches</td>
									<td>{{ averageData2024.totalMatches }}</td>
									<td>{{ averageData2023.totalMatches }}</td>
								</tr>
								<tr>
									<td>Avg mins per game</td>
									<td>
										{{
											averageData2024.AvgMin
												| number : "1.1-1"
										}}
									</td>
									<td>
										{{
											averageData2023.AvgMin
												| number : "1.1-1"
										}}
									</td>
								</tr>
								<tr>
									<td>Avg runs</td>
									<td>
										{{
											averageData2024.avgRuns
												| number : "1.1-1"
										}}
									</td>
									<td>
										{{
											averageData2023.avgRuns
												| number : "1.1-1"
										}}
									</td>
								</tr>
								<tr>
									<td>Avg meters</td>
									<td>
										{{
											averageData2024.avgMetres
												| number : "1.1-1"
										}}
									</td>
									<td>
										{{
											averageData2023.avgMetres
												| number : "1.1-1"
										}}
									</td>
								</tr>
								<tr>
									<td>Avg meters per run</td>
									<td>
										{{
											averageData2024.avgMetersPerRun
												| number : "1.1-1"
										}}
									</td>
									<td>
										{{
											averageData2023.avgMetersPerRun
												| number : "1.1-1"
										}}
									</td>
								</tr>
								<tr>
									<td>Tries</td>
									<td>
										{{
											averageData2024.totalTries
												| number : "1.0-0"
										}}
									</td>
									<td>
										{{
											averageData2023.totalTries
												| number : "1.0-0"
										}}
									</td>
								</tr>
								<tr>
									<td>Tackle Breaks</td>
									<td>
										{{
											averageData2024.totalTackleBreaks
												| number : "1.0-0"
										}}
									</td>
									<td>
										{{
											averageData2023.totalTackleBreaks
												| number : "1.0-0"
										}}
									</td>
								</tr>
								<tr>
									<td>Linebreaks</td>
									<td>
										{{
											averageData2024.totalLinebreaks
												| number : "1.0-0"
										}}
									</td>
									<td>
										{{
											averageData2023.totalLinebreaks
												| number : "1.0-0"
										}}
									</td>
								</tr>
								<tr>
									<td>Linebreaks Assists</td>
									<td>
										{{
											averageData2024.totalLinebreakAssists
												| number : "1.0-0"
										}}
									</td>
									<td>
										{{
											averageData2023.totalLinebreakAssists
												| number : "1.0-0"
										}}
									</td>
								</tr>
								<tr>
									<td>Try Assists</td>
									<td>
										{{
											averageData2024.totalTryAssists
												| number : "1.0-0"
										}}
									</td>
									<td>
										{{
											averageData2023.totalTryAssists
												| number : "1.0-0"
										}}
									</td>
								</tr>
								<tr>
									<td>Offloads</td>
									<td>
										{{
											averageData2024.totalOffloads
												| number : "1.0-0"
										}}
									</td>
									<td>
										{{
											averageData2023.totalOffloads
												| number : "1.0-0"
										}}
									</td>
								</tr>
								<tr>
									<td>Erros</td>
									<td>
										{{
											averageData2024.totalErrors
												| number : "1.0-0"
										}}
									</td>
									<td>
										{{
											averageData2023.totalErrors
												| number : "1.0-0"
										}}
									</td>
								</tr>
								<tr>
									<td>Penalties</td>
									<td>
										{{
											averageData2024.totalPenalties
												| number : "1.0-0"
										}}
									</td>
									<td>
										{{
											averageData2023.totalPenalties
												| number : "1.0-0"
										}}
									</td>
								</tr>
								<tr>
									<td>Tackle Eff%</td>
									<td>
										{{
											averageData2024.efficiency
												| number : "1.1-1"
										}}%
									</td>
									<td>
										{{
											averageData2023.efficiency
												| number : "1.1-1"
										}}%
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="spacer"></div>
</div>
