import {
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
	ElementRef,
	Renderer2,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import { ProgressBarRendererComponent } from './progress-bar-renderer/progress-bar-renderer.component';

import {
	AccountService,
	AlertService,
	ChartService,
	CSVService,
	FileService,
} from '@app/_services';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
// import * as Highcharts from 'highcharts';
import { Subject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
// import { IDayCalendarConfig, DatePickerComponent } from "ng2-date-picker";
import * as moment from 'moment';

@Component({
	selector: 'player-report',
	templateUrl: 'report.component.html',
	styleUrls: ['report.component.scss'],
})
export class PlayerReportsComponent implements OnInit {
	@ViewChild('resumeInput', { static: true }) resumeInput;

	loading = false;
	submitted = false;
	pic: any;
	picture: any;
	filepath: any;
	config = {
		dialogsInBody: true,
		placeholder: '',
		tabsize: 2,
		height: '200px',
	};

	datePickerConfig: any;

	form: FormGroup;
	playersData = null;

	itemsPerPage: any;
	totalItems: any;
	items: any;

	filternewname: any;
	viewplayer: any;
	reports: any;

	playerName: any;
	competitions: any;
	filters: any;
	rowdata = [
		{ id: 1, name: '10' },
		{ id: 2, name: '20' },
		{ id: 3, name: '50' },
		{ id: 4, name: '100' },
		{ id: 5, name: '200' },
		{ id: 5, name: '500' },
		{ id: 5, name: '1000' },
	];
	// CREATE FILTER

	// options: DatepickerOptions = {
	// 	minYear: getYear(new Date()) - 30, // minimum available and selectable year
	// 	maxYear: getYear(new Date()) + 30, // maximum available and selectable year
	// 	placeholder: '', // placeholder in case date model is null | undefined, example: 'Please pick a date'
	// 	format: 'LLLL do yyyy', // date format to display in input
	// 	formatTitle: 'LLLL yyyy',
	// 	formatDays: 'EEEEE',
	// 	firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
	// 	locale: locale, // date-fns locale
	// 	position: 'bottom',
	// 	inputClass: '', // custom input CSS class to be applied
	// 	calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
	// 	scrollBarColor: '#dfe3e9', // in case you customize you theme, here you define scroll bar color
	// 	keyboardEvents: true, // enable keyboard events
	// };

	competitionName: any;
	seasonName: any;
	playerFilterName: any;

	NewData: any = [];
	NewDataAVG: any = [];
	Oponent: any = [];
	RoundOponent: any = [];

	// NEW ARRAY
	season: any = [];
	competition: any = [];
	position: any;
	sourceteam: any;
	playerCVG: any;
	stats: any;
	last5match: any;
	totalmin: any;
	totalavg: any;
	totalavgplus: any;
	totalCVG: any;
	totalCVM: any;
	tdlength = 8;
	page = 1;

	columnDefs: any;
	rowData: [];
	defaultColDef: any;
	player: any;
	colmvis = [];
	columnOption = [];
	calculatedReport: any = [];
	date = new Date();
	comphighCVG: any;
	comphighCVM: any;
	playerCVM: any;
	progress: number = 70; // Example progress value
	averageData2023: any;
	averageData2024: any;
	topPlayersData: any[] = [];
	maxCvg: number = 0;
	maxCvm: number = 0;
	maxCreativity: number = 0; // Assuming you have creativity values
	maxNegs: number = 0;
	maxAbsNegs: number = 0;
	minMatches: number = 5;

	constructor(
		private modalService: NgbModal,
		private formBuilder: FormBuilder,
		private accountService: AccountService,
		private route: ActivatedRoute,
		private router: Router,
		private service: FileService,
		private alertService: AlertService,
		private http: HttpClient,
		private ChartService: ChartService,
		private CSV: CSVService,
		private uploadService: FileService
	) {
		this.route.params.subscribe(
			(params) => (this.playersData = params.Players)
		);
	}
	ngOnInit(): void {
		this.form = this.buildForm();
		this.itemsPerPage = '';
		this.competitionName = this.route.snapshot.params['name']
			.toString()
			.split('&')[0]
			.replace(/-/g, ' ');
		this.season = this.route.snapshot.params['name']
			.toString()
			.split('&')[1]
			.replace(/-/g, ' ');
		this.playerName = this.route.snapshot.params['name']
			.toString()
			.split('&')[2]
			.replace(/(?<!-)-(?!-)/g, ' ')
			.replaceAll('--', '-');
		this.position = this.route.snapshot.params['name']
			.toString()
			.split('&')[3]
			.replace(/-/g, ' ');
		this.sourceteam = this.route.snapshot.params['name']
			.toString()
			.split('&')[4]
			.replace(/-/g, ' ');
		this.playerCVG = this.route.snapshot.params['name']
			.toString()
			.split('&')[5];
		this.comphighCVM = this.route.snapshot.params['name']
			.toString()
			.split('&')[6];
		this.comphighCVG = this.route.snapshot.params['name']
			.toString()
			.split('&')[7];
		this.playerCVM = this.route.snapshot.params['name']
			.toString()
			.split('&')[8];
		this.filepath = `${environment.imgPath}/`;

		var newdate = moment(this.date).format('DD-MM-YYYY');
		this.datePickerConfig = {
			format: 'DD-MM-YYYY',
			showNearMonthDays: true,
			minYear: 1970,
			maxYear: 2030,
			max: newdate,
		};

		// this.CreateStat(this.season, this.competitionName, this.playerName);
		this.getReport(
			1,
			this.playerName,
			this.competitionName,
			this.season,
			false
		);
		this.getAverages(1, this.playerName, this.competitionName, '2024');
		this.getAverages(1, this.playerName, this.competitionName, '2025');

		this.getTop(
			1,
			this.playerName,
			this.competitionName,
			this.season,
			this.minMatches
		);
		if (localStorage.getItem('class')) {
			this.colmvis = JSON.parse(localStorage.getItem('class'));
		} else {
			this.colmvis = [];
		}

		console.log('NGONINIT Completed');
	}

	ColumnVisiblity(key) {
		if (!this.colmvis.includes(key)) {
			//checking weather array contain the id
			this.colmvis.push(key); //adding to array because value doesnt exists
		} else {
			this.colmvis.splice(this.colmvis.indexOf(key), 1); //deleting
		}
		localStorage.setItem('class', JSON.stringify(this.colmvis));
		this.getReport(
			1,
			this.playerName,
			this.competitionName,
			this.season,
			false
		);
		this.getAverages(1, this.playerName, this.competitionName, '2024');
		this.getAverages(1, this.playerName, this.competitionName, '2025');
		this.getTop(
			1,
			this.playerName,
			this.competitionName,
			this.season,
			this.minMatches
		);
	}

	returnZero() {
		return 0;
	}

	getPlayerName() {
		this.accountService.getPlayersNames().subscribe((playerName) => {
			this.playerName = playerName;
		});
	}

	getCompetitionsName() {
		this.accountService.getCompetitions().subscribe((competitions) => {
			this.competitions = competitions;
		});
	}

	getFilter() {
		this.accountService.getPlayersFilter('').subscribe((filters) => {
			this.filters = filters;
			for (let newseason of this.filters.data.Season) {
				this.season = [newseason.value];
			}
			for (let newseason of this.filters.data.Competition) {
				this.competition = [newseason.value];
			}
		});
	}

	cvgbar: any;
	cvmbar: any;
	CreateStat(season, compname, plname) {
		this.http
			.get(
				`${environment.apiUrl}/report/stats/player?season=${season}&competition_name=${compname}&player=${plname}`
			)
			.pipe(first())
			.subscribe((stats) => {
				this.stats = stats;
				this.cvgbar = (this.playerCVG / this.stats.data.high_cvg) * 100;
				this.cvmbar = (this.playerCVM / this.comphighCVM) * 100;
			});
	}

	getCalculatedReport() {
		this.http
			.get(
				`${environment.apiUrl}/calculate/player?page=${1}
		&competition_name=${this.competitionName}&season=${this.season}
		&limit=&player=${this.playerName}`
			)
			.subscribe((data: any) => {
				this.calculatedReport = data;
				var category = [
					'Matches',
					'Avg Mins',
					'Avg CVG',
					'Avg CVM',
					'Avg CPC',
					'Avg NegPM',
				];
				var items = [];

				this.calculatedReport.data.map((x) => {
					if ((x['Negative Per Min'] = null)) {
					}
					items.push(
						x.MAT,
						x.MIN,
						x.CVG,
						x.CVM,
						x['CPC %'],
						x['AVG-']
					);
				});

				// this.ChartService.CaculatedReportCharts(category, items)
			});
	}

	pageitem(itemsPerPage) {
		this.getReport(
			1,
			this.playerName,
			this.competitionName,
			this.season,
			false
		);
		this.getAverages(1, this.playerName, this.competitionName, '2024');
		this.getAverages(1, this.playerName, this.competitionName, '2025');
		this.getTop(
			1,
			this.playerName,
			this.competitionName,
			this.season,
			this.minMatches
		);
	}

	maxCVG: any;
	HighRound: any;
	LowRound: any;
	Lowopo: any;
	Highopo: any;
	LowCVG: any;
	last5AVG: any;
	last5CVG: any;
	last5CVM: any;
	last5NEG: any;
	last5CPC: any;
	last5AVGArray: any;

	getReport(
		page,
		playerName,
		competitionName,
		season,
		previousSeason: boolean
	) {
		this.NewData = [];
		this.NewDataAVG = [];
		this.Oponent = [];
		this.RoundOponent = []; // New list for round + opponent

		this.http
			.get(
				`${environment.apiUrl}/report/player?page=${page}&limit=${this.itemsPerPage}
			&player=${playerName}&competition_name=${competitionName}&season=${season}`
			)
			.subscribe((data: any) => {
				this.reports = data;
				this.getCalculatedReport();
				const sourceTeams = this.reports.data.map(
					(report) => report['Source Team']
				);

				this.last5AVGArray = this.reports.data.slice(-5);

				// Calculate averages for last 5 matches
				var totalavg5 = this.last5AVGArray.reduce(
					(accumulator, current) => accumulator + current['MIN'],
					0
				);
				this.last5AVG = Math.round(
					totalavg5 / this.last5AVGArray.length
				);

				var totalcvg5 = this.last5AVGArray.reduce(
					(accumulator, current) => accumulator + current['CVG'],
					0
				);
				this.last5CVG = Math.round(
					totalcvg5 / this.last5AVGArray.length
				);

				var totalcvm5 = this.last5AVGArray.reduce(
					(accumulator, current) => accumulator + current['CVM'],
					0
				);
				this.last5CVM = Math.round(
					totalcvm5 / this.last5AVGArray.length
				);

				var totalneg5 = this.last5AVGArray.reduce(
					(accumulator, current) =>
						accumulator + Number(current['Neg Pm']),
					0
				);
				this.last5NEG = (totalneg5 / this.last5AVGArray.length).toFixed(
					1
				);

				var totalcpc5 = this.last5AVGArray.reduce(
					(accumulator, current) => {
						const cpcValue = current['CPC'];
						if (cpcValue && typeof cpcValue === 'string') {
							return (
								accumulator + Number(cpcValue.replace(/%/g, ''))
							);
						}
						return accumulator;
					},
					0
				);

				this.last5CPC =
					Math.round(totalcpc5 / this.last5AVGArray.length) + '%';

				let maxObj = data.data.reduce((max, obj) =>
					max['CVG'] > obj['CVG'] ? max : obj
				);
				let minObj = data.data.reduce((min, obj) =>
					min['CVG'] < obj['CVG'] ? min : obj
				);

				this.maxCVG = maxObj['CVG'];
				this.HighRound = maxObj['Round'];
				this.Highopo = maxObj['Oponent'];
				this.LowCVG = minObj['CVG'];
				this.Lowopo = minObj['Oponent'];
				this.LowRound = minObj['Round'];

				var totalavg = data.data.reduce(
					(accumulator, current) => accumulator + current['AVG-'],
					0
				);
				this.totalavg = (totalavg / data.data.length).toFixed(1);

				var totalavgplus = data.data.reduce(
					(accumulator, current) => accumulator + current['AVG+'],
					0
				);
				this.totalavgplus = (totalavgplus / data.data.length).toFixed(
					1
				);

				var totalmin = data.data.reduce(
					(accumulator, current) => accumulator + current['MIN'],
					0
				);
				this.totalmin = Math.round(totalmin / data.data.length);

				var totalCVG = data.data.reduce(
					(accumulator, current) => accumulator + current['CVG'],
					0
				);
				this.totalCVG = (totalCVG / data.data.length).toFixed(1);

				var totalCVM = data.data.reduce(
					(accumulator, current) => accumulator + current['CVM'],
					0
				);
				this.totalCVM = (totalCVM / data.data.length).toFixed(1);

				this.reports.data.push({
					'Match Date': '',
					Round: '',
					'Source Team': '',
					Oponent: '',
					'Turn Around': '',
					Position: '',
					'Game Position': '',
					Result: '',
					MIN: this.totalmin,
					'AVG+': this.totalavgplus,
					'AVG-': this.totalavg,
					CVG: this.totalCVG,
					CVM: this.totalCVM,
					CPC: '',
					'Neg Pm': '',
				});

				// Populate NewData, NewDataAVG, Oponent, and RoundOponent arrays with real data
				data.data.forEach((report, index) => {
					this.NewData.push({ y: report['CVG'], Result: '' });
					this.NewDataAVG.push({ y: report['AVG-'], Result: '' });
					this.Oponent.push(`D ${index + 1}`);
					this.RoundOponent.push(
						`${report['Round']} ${report['Oponent']}`
					);
				});

				console.log('NewData:', this.NewData);
				console.log('NewDataAVG:', this.NewDataAVG);
				console.log('Oponent:', this.Oponent);
				console.log('RoundOponent:', this.RoundOponent);

				this.last5match = this.NewData.slice(
					Math.max(this.NewData.length - 5, 1)
				);

				this.ChartService.CreateCharts2(
					this.RoundOponent,
					this.NewData,
					this.NewDataAVG
					// this.RoundOponent // Pass the RoundOponent list to CreateCharts
				);

				this.defaultColDef = {
					resizable: true,
					sortable: true,
					minWidth: 100,
				};
				var columnDefss = [];

				for (let x of Object.keys(this.reports.data[0])) {
					var left;
					var getWidth = x.length * 10;
					var hidecol;

					if (localStorage.getItem('class')) {
						if (
							JSON.parse(localStorage.getItem('class')).includes(
								x
							)
						) {
							hidecol = true;
							setTimeout(() => {
								document.getElementById(
									x.replace(/\s/g, '')
								).style.opacity = '.5';
							}, 1000);
						} else {
							hidecol = false;
						}
					}

					var add = true;
					if (x === 'Source Team') {
						getWidth = 200;
					}
					if (x === 'Position') {
						getWidth = 150;
					}
					if (x === 'Oponent') {
						getWidth = 150;
					}
					if (x === 'Turn Around') {
						getWidth = 150;
					}

					if (x === 'AVG+') {
						getWidth = 150;
					}

					if (x === 'Position') {
						getWidth = 150;
						add = true;
					}

					if (x === 'Game Position') {
						getWidth = 150;
						add = false;
					}

					if (x === 'CVM') {
						getWidth = 150;
						add = true;
					}

					if (x === 'Round') {
						getWidth = 150;
						add = false;
					}

					if (add) {
						columnDefss.push({
							headerName: x,
							field: x,
							width: getWidth,
							hide: hidecol,
							// pinned: left,
						});
					}
				}

				this.columnDefs = columnDefss;
				this.rowData = this.reports.data;
			});
	}

	getAverages(page, playerName, competitionName, season) {
		this.http
			.get(
				`${environment.apiUrl}/report/avg?page=${page}&limit=${this.itemsPerPage}
			&player=${playerName}&competition_name=${competitionName}&season=${season}
			`
			)
			.subscribe((data: any) => {
				console.log('Result from AVG', data);
				const noData = {
					avgRuns: 'NA',
					avgMetres: 'NA',
					avgMetersPerRun: 'NA',
					avgPostMeter: 'NA',
					avgTries: 'NA',
					avgTackleBreaks: 'NA',
					avgOffloads: 'NA',
					avgLinebreaks: 'NA',
					avgAssists: 'NA',
					avgErrors: 'NA',
					avgPenalties: 'NA',
				};

				if (data && data.data) {
					if (season === '2024') {
						this.averageData2023 = data.data;
					} else if (season === '2025') {
						this.averageData2024 = data.data;
					}
				} else {
					if (season === '2024') {
						this.averageData2023 = noData;
					} else if (season === '2025') {
						this.averageData2024 = noData;
					}
				}
			});
	}

	getTop(page, playerName, competitionName, season, min_matches) {
		this.http
			.get(
				`${environment.apiUrl}/report/top?player=${this.playerName}&competition_name=${this.competitionName}&season=${this.season}&min_matches=${min_matches}&position=${this.position}`
			)
			.subscribe((response: any) => {
				if (response.success) {
					this.topPlayersData = response.data.map((player, index) => {
						const creativity =
							player.pos !== 0
								? (player.pos / (player.pos - player.negs)) *
								  100
								: 0;
						// const creativity =  0;

						return {
							rank: player.rank,
							player: player._id,
							team: player.team, // Replace with actual team name if available
							position: player.position, // Replace with actual position if available
							mat: player.mat,
							cpc: player.cpc,
							mins: player.min,
							cvg: player.cvg.toFixed(1),
							cvm: player.cvm.toFixed(1),
							creativity: creativity.toFixed(1),
							negs: player.negs.toFixed(1),
							absNegs: Math.abs(player.negs).toFixed(1), // Absolute value for width calculation
							winPercentage: (
								(player.wins / player.count) *
								100
							).toFixed(1),
						};
					});

					// Calculate maximum values
					this.maxCvg = Math.max(
						...response.data.map((player) => player.cvg)
					);
					this.maxCvm = Math.max(
						...response.data.map((player) => player.cvm)
					);
					this.maxAbsNegs = Math.max(
						...response.data.map((player) => Math.abs(player.negs))
					);
					this.maxCreativity = Math.max(
						...this.topPlayersData.map((player) =>
							parseFloat(player.creativity)
						)
					);
				}
			});
	}

	onMinMatchesChange() {
		if (Number.isInteger(this.minMatches) && this.minMatches > 0) {
			this.getTop(
				1,
				this.playerName,
				this.competitionName,
				this.season,
				this.minMatches
			);
		} else {
			console.error('Min Matches must be a positive integer');
		}
	}

	onInput(event: Event) {
		const input = event.target as HTMLInputElement;
		input.value = input.value.replace(/[^0-9]/g, '');
		this.minMatches = Number(input.value);
	}

	getPercentageWidth(value: number, maxValue: number): string {
		return `${(value / maxValue) * 100}%`;
	}

	csv() {
		this.CSV.downloadFile(
			this.reports.data,
			this.playerName,
			Object.keys(this.reports.data[0])
		);
	}

	openModal(profile) {
		this.modalService.open(profile, { size: 'md' });
	}
	viewInfo(info) {
		this.modalService.open(info, { size: 'md' });
	}

	// PROFILE EDIT
	get f() {
		return this.form.controls;
	}

	addFileDetails() {
		this.loading = true;
		let formData = new FormData();
		formData.append('file', this.resumeInput.nativeElement.files[0]);
		this.uploadService.addFileDetails(formData).subscribe(
			(result: any) => {
				this.pic = result;
				this.picture = this.pic.newfilename;
				this.loading = false;
			},
			(error) => {
				this.alertService.error(error);
				this.loading = false;
				console.log(error);
			}
		);
	}
	plname: any;

	private buildForm(): FormGroup {
		return this.formBuilder.group({
			image: [],
			nick_name: [],
			player_name: [],
			dob: [],
			birth_place: [],
			height: [],
			weight: [],
			age: [],
		});
	}

	updateFormValue(): void {
		this.accountService.getPlayer(this.playerName).subscribe((data) => {
			this.player = data;
			this.picture = this.player.data.image;
			this.f.player_name.setValue(this.player.data.player_name);
			this.f.nick_name.setValue(this.player.data.nick_name);
			this.f.image.setValue(this.player.data.image);
			this.f.birth_place.setValue(this.player.data.birth_place);
			this.f.dob.setValue(
				moment(this.player.data.dob).format('DD-MM-YYYY')
			);
			this.f.height.setValue(this.player.data.height);
			this.f.weight.setValue(this.player.data.weight);
			this.f.age.setValue(this.player.data.age);
		});
	}

	onSubmit(): void {
		this.submitted = true;
		this.alertService.clear();
		if (this.form.invalid) {
			return;
		}
		// console.log(this.form.value)
		this.loading = true;
		this.updatePlayer().subscribe(
			() => {
				// this.router.navigate(['/admin/athlete']);
				this.modalService.dismissAll();
			},
			(error) => {
				this.alertService.error(error);
				this.loading = false;
			}
		);
	}

	getGradient(value: number): string {
		return `linear-gradient(90deg, rgba(0,242,96,1) ${value}%, rgba(5,117,230,1) ${value}%)`;
	}

	updatePlayer() {
		return this.accountService.updatePlayer(this.form.value).pipe(
			tap((data) => {
				this.updateFormValue();
				this.loading = false;
				this.alertService.success('Player Update successful', {
					keepAfterRouteChange: true,
				});
			})
		);
	}
}
